
.suggestions {
    border: 1px solid #666666;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 200px;
    overflow-y: auto;
    padding-left: 0;
}

.suggestions li {
    padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
    background-color: #000091;
    color: #fff;
    cursor: pointer;
    font-weight: 700;
}
